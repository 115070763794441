import '@/assets/index.sass';
import type { Theme } from 'vitepress';
import AppLayoutDefault from '@/layouts/AppLayoutDefault.vue';
import { createPinia } from 'pinia';
import VueNotification from '@kyvg/vue3-notification';
import { defineAsyncComponent, hydrateOnVisible } from 'vue';
import VHighlight from 'UiKit/components/Ads/VHighlight.vue';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VTooltip = defineAsyncComponent({
  loader: () => import('UiKit/components/VTooltip/VTooltip.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VButton = defineAsyncComponent({
  loader: () => import('UiKit/components/VButton/VButton.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VSvgIcon = defineAsyncComponent({
  loader: () => import('UiKit/components/VSvgIcon/VSvgIcon.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VTag = defineAsyncComponent({
  loader: () => import('UiKit/components/VTag/VTag.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
// const VHighlight = defineAsyncComponent({
//   loader: () => import('UiKit/components/Ads/VHighlight.vue'),
//   // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
//   hydrate: hydrateOnVisible(),
// });
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VSectionCaseStudiesMain = defineAsyncComponent({
  loader: () => import('UiKit/components/Section/VSectionCaseStudiesMain.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VSliderWithCardBlueLine = defineAsyncComponent({
  loader: () => import('UiKit/components/Sliders/VSliderWithCardBlueLine.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VWeHelp = defineAsyncComponent({
  loader: () => import('UiKit/components/Ads/VWeHelp.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VCardCheckmarked = defineAsyncComponent({
  loader: () => import('UiKit/components/Cards/VCardCheckmarked.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});

export default {
  Layout: AppLayoutDefault,
  enhanceApp({ app, router, siteData }) {
    const pinia = createPinia();
    app.use(pinia)
    app.use(VueNotification)
    app.component('VTooltip', VTooltip)
    app.component('VButton', VButton)
    app.component('VSvgIcon', VSvgIcon)
    app.component('VTag', VTag)
    app.component('VHighlight', VHighlight)
    app.component('VSectionCaseStudiesMain', VSectionCaseStudiesMain)
    app.component('VSliderWithCardBlueLine', VSliderWithCardBlueLine)
    app.component('VWeHelp', VWeHelp)
    app.component('VCardCheckmarked', VCardCheckmarked)
  }
} satisfies Theme
